import Vue from 'vue'
import Router from 'vue-router'
import routers from './router'
import App from './App'
import VCharts from 'v-charts'
import axios from 'axios'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

Vue.use(Router);
Vue.use(VCharts)
Vue.use(Antd);
Vue.use(preview)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.prototype.$axios = axios
  //axios.defaults.baseURL = 'http://127.0.0.1:9997'
axios.defaults.baseURL = 'http://gxhbpaas.cn:9997'
axios.defaults.headers.common['token'] = sessionStorage.getItem("token")

//token失效返回登录界面
axios.interceptors.response.use(response => {
	if (response) {
		if (response.data.code === "401") {
			sessionStorage.removeItem("token");
			window.location = '/login';
		}
	}
	return response;
}, error => {
	return Promise.reject(error.response.data) //返回接口返回的错误信息
})


const router = new Router({
	mode: 'history',
	routes: routers
})
new Vue({
	el: '#app',
	router,
	render: h => h(App)
})
