<template>
  <div
      style="
    background-image:url(https://cdn.gxhbpaas.cn/background.png);
    background-color:#e8eaf2;
    background-size: cover;
    height: 100%;
    background-position: 50% 50%;
    justify-content: center;
    align-items: center;
    display: flex">
       <span style="width: 100%;height: 100%">
         <div style="width: 500px;height: 374px;top: 50%;left: 50%;position: absolute;background-color: white;margin-top: -280.5px;margin-left: -250px;background-color: #b4ecfa">

			<div style="width: 500px;height: 47px;margin-bottom: 35px">
        <img src="https://cdn.gxhbpaas.cn/logo.png" alt="" style="display: inline-block;margin-top: -20px;width: 150px;height: 47px;margin-left: 25px">
        <p style="font-size: 28px;margin-left: 40px;width: 280px;height: 47px;display: inline;line-height: 47px">杭州申辉平台管理系统</p>
      </div>

			<div style="width: 524px;height: 374px;margin-top: 40px;background: url('https://platform.eacheart.com/common/images/login_bg.png');border: 12px solid rgba(22,111,198,.48);border-radius: 12px;-webkit-box-shadow: 0 0 5px rgb(0 0 0 / 40%)">

				<div style="width: 250px;height: 164px;margin-left: 220px">
					<div style="font-size: 18px;margin: 20px 0">欢迎您登录！</div>
					<div>
            <a-input placeholder="请输入账号" v-model="mobile" style="width: 250px;height: 35px"/>
          </div>
					<div>
             <a-input type="password" placeholder="请输入密码" v-model="password" style="width: 250px;height: 35px;margin: 10px 0"/>
            </div>
          <a-button html-type="submit" v-on:click="login" style="width: 100%;background: #06cfa5;margin-top: 20px">
            登录
          </a-button>


			</div>
		</div>
</div>

         <a style="border:1px solid green;position:absolute;left:0;bottom:0;width: 100%;text-align: center" href="http://beian.miit.gov.cn">浙ICP备19000748号-3</a>
       </span>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      mobile: '',
      password: '',
    }
  },
  methods: {
    login: function () {
      axios.post("/user/login", {
        mobile: this.mobile,
        password: this.password
      }).then((res) => {
        if (res.data.code === '200'){
          //将token放入浏览器缓存，表明用户已登录。每次浏览器页面关闭，该缓存失效
          sessionStorage.setItem("token", res.data.data.token);
          sessionStorage.setItem("role", res.data.data.role);
          sessionStorage.setItem("name", res.data.data.name);
          sessionStorage.setItem("id",res.data.data.id);
          sessionStorage.setItem("version",res.data.data.version);
          //相当于每次请求带上token 让服务器鉴权
          axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
          this.$router.push("/")
        } else {
          this.$message.error(res.data.msg);
        }

      })
    }
  }
}
</script>
