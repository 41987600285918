<template>
	<div>
    <a-breadcrumb style="padding-top: 15px">
			<a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
			<a-breadcrumb-item>员工管理</a-breadcrumb-item>
			<a-breadcrumb-item>员工列表</a-breadcrumb-item>
		</a-breadcrumb>
		<a-page-header style="border: 1px solid rgb(235, 237, 240)" title="员工列表" />
		<br>
		<a-row>
			<a-col :xs="22" :xl="22" :xxl="23">
			</a-col>
			<a-col :span="1">
				<a-button type="primary" v-on:click="add">
					添加
				</a-button>
			</a-col>
		</a-row><br>
		<a-row>
			<a-row type="flex">
				<a-col><span style="line-height: 32px">员工姓名：</span></a-col>
				<a-col>
					<a-input placeholder="员工姓名：" v-model="name" type="name" style="width: 150px"></a-input>
				</a-col>
				<a-col offset="1"><span style="line-height: 32px">手机号：</span></a-col>
				<a-col>
					<a-input placeholder="手机号：" v-model="mobile" style="width: 150px"></a-input>
				</a-col>
			</a-row>
			<br>
			<a-col>
        <span style="width: 70px;height: 19px;">员工信息：</span>
				<a-input placeholder="部门/职位" v-model="departPosition" style="width: 150px"></a-input>
				<a-button v-on:click="search" type="primary">搜索</a-button>
				<a-button v-on:click="reset">重置</a-button>
			</a-col>

		</a-row><br>
		<a-table :columns="columns" :data-source="list" :pagination="pagination" @change="handleTableChange">
			<span slot="employeeState" slot-scope="employeeState">
				<a-tag :color="employeeState === '1' ? 'green' :  'red'">
					{{ employeeState === '1' ? '在职' : '离职' }}
				</a-tag>
			</span>
			<span slot="operation" slot-scope="text,record">
				<a-button v-on:click="edit(record.id)">编辑</a-button>
				<a-button v-on:click="modify(record)">修改权限</a-button>
			</span>
		</a-table>

<!--    编辑弹出框-->
		<a-modal v-model="visible" @ok="handleOk" okText="保存" cancelText="取消" @cancel="handleCancel">
			<p>手机号：
				<a-input v-model="editMobile"></a-input>
			</p>
			<p>姓名：
				<a-input v-model="editName"></a-input>
			</p>
      <p>修改状态：
        <a-radio-group default-value="a" button-style="solid" v-model="employState" @change="onChange">
          <a-radio-button value="1">
            在职
          </a-radio-button>
          <a-radio-button value="2">
            离职
          </a-radio-button>
        </a-radio-group>
      </p>

		</a-modal>

		<a-modal v-model="leaveVisible" @ok="leaveOk" okText="确认" cancelText="取消" @cancel="leaveCancel">
			<p>是否确认此员工已离职？</p>
		</a-modal>

		<!--修改权限弹窗-->
		<a-modal v-model="modifyVisible" @ok="modifyOk" okText="确认" cancelText="取消" @cancel="modifyCancel">
			<a-select style="width: 120px" @change="handleChange" v-model="modifyRole">
				<a-select-option :key="item.id" v-for="item in roles" :value="item.id">{{item.roleName}}
				</a-select-option>
			</a-select>
		</a-modal>

		<!--添加人员弹窗-->
		<a-modal v-model="addVisible" @ok="addOk" okText="确认" cancelText="取消" @cancel="addCancel">
			<p>员工名称：
				<a-input v-model="userName">
					<a-icon slot="prefix" type="user" />
				</a-input>
			</p>
			<p>手机号：
				<a-input v-model="phoneNumber">
					<a-icon slot="prefix" type="phone" />
				</a-input>
			</p>
			<p>职位：
				<a-input v-model="position" placeholder="请输入职位:专员/助理...">
					<a-icon slot="prefix" type="solution" />
				</a-input>
			</p>
			<p>权限：
				<a-select style="width: 120px" @change="selectHandleChange" :defaultValue="selectRole">
					<a-select-option :key="item.id" v-for="item in addRoles" :value="item.id">{{item.remarks}}
					</a-select-option>
				</a-select>
			</p>
			<p>部门 ：
				<a-select :default-value="department" style="width: 120px" @change="departHandleChange">
					<a-select-option value="技术部">
						技术部
					</a-select-option>
					<a-select-option value="项目部">
						项目部
					</a-select-option>
					<a-select-option value="行政部">
						行政部
					</a-select-option>
					<a-select-option value="市场部">
						市场部
					</a-select-option>
					<a-select-option value="商务部">
						商务部
					</a-select-option>
					<a-select-option value="总经办部">
						总经办部
					</a-select-option>
				</a-select>
			</p>
		</a-modal>

	</div>
</template>
<script>
	import axios from "axios";

	const columns = [{
			title: '员工名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '手机号',
			dataIndex: 'mobile',
			key: 'mobile',

		},
		{
			title: '员工状态',
			dataIndex: 'employeeState',
			key: 'employeeState',
			scopedSlots: {
				customRender: 'employeeState'
			}

		},
		{
			title: '部门',
			dataIndex: 'departmentName',
			key: 'departmentName',
			scopedSlots: {
				customRender: 'departmentName'
			}

		},
		{
			title: '职位',
			dataIndex: 'positionName',
			key: 'positionName',
			scopedSlots: {
				customRender: 'positionName'
			}

		},
		{
			title: '操作',
			dataIndex: 'operation',
			scopedSlots: {
				customRender: 'operation'
			}

		}
	];


	export default {
		data() {
			return {
				pagination: {
					total: 0,
					current: 1,
					pageSize: 10,
					showTotal: total => `共 ${total} 条`, // 显示总数

				},
				columns,
				name: "", //搜索框姓名
				mobile: "", //搜索框手机号
				list: [], //列表数据
				editId: "", //编辑框员工的id
				editName: "", //编辑框姓名
				editMobile: "", //编辑框手机号
				visible: false, //编辑框是否可见
				leaveVisible: false, //离职确认框
				leaveId: "", //离职人员id
				modifyId: "", //当权用户的id
				modifyVisible: false,
				modifyRole: "", //当前用户的权限
				roles: [], //所有的权限
				addVisible: false,
				userName: "", //添添加弹窗用户名
				phoneNumber: "", //添加弹窗手机号
				size: "default",
				addRoles: [], //添加弹窗下拉框权限
				selectRole: "", //添加弹窗权限
				department: "项目部", //添加弹窗部门
        // area:"盐渎街道",
				position: "", //职位
        responsibleValue: [],//负责区域
				departPosition: "",//搜索框
				resVisible: false,
        employState: "1",
			}
		},
		created() {
			this.getData();

		},
		methods: {
			search: function() {
				this.pagination.current = 1;
				this.getData();
			},
			getData: function() {
				axios.post("/employee/list", {
						"pageSize": this.pagination.current,
						"name": this.name,
						"phone": this.mobile,
						"departmentPosition": this.departPosition
					}).then((res) => {
						if (res.data.code === '200') {
							//请求成功
							this.list = res.data.data.list;
							this.pagination.total = parseInt(res.data.data.count);
						}
					})
			},
			//重置
			reset: function() {
				this.name = "";
				this.mobile = "";
				this.departPosition = "";
				this.pagination.current = 1;
				this.getData();
			},
			//分页监听方法
			handleTableChange: function(pagination) {
				this.pagination = pagination;
			},
			//编辑按钮
			edit: function(id) {
				this.visible = true;
				this.editId = id;
				axios.post("/employee/edit", {
					"id": id
				}).then((res) => {
					if (res.data.code === '200') {
						//请求成功,需要将服务器返回的值放入内存中
						this.editName = res.data.data.name;
						this.editMobile = res.data.data.phone;
            this.employState = res.data.data.state;
					} else {
            this.$message.success(res.data.msg);
					}
				})

			},
			//编辑框确认
			handleOk: function() {
				this.visible = false;
				axios.post("/employee/save", {
					"id": this.editId,
					"name": this.editName,
					"phone": this.editMobile,
          "state": this.employState
				}).then((res) => {
					if (res.data.code === '200') {
            this.$message.success(res.data.msg);

						this.getData();
					}
				})
			},

      //
      onChange(e){
        console.log(e.target.value);
        this.employState = e.target.value;
      },


			//编辑框取消
			handleCancel: function() {
				this.editId = "";
				this.visible = false;
				this.editName = "";
				this.editMobile = ""
			},
			//离职按钮
			leave: function(id) {
				this.leaveVisible = true;
				this.leaveId = id;

			},

			//离职框确认
			leaveOk: function() {
				this.leaveVisible = false;
				axios.post("/employee/leaveOffice", {
					"id": this.leaveId,
				}).then((res) => {
					if (res.data.code === '200') {
            this.$message.success(res.data.msg);
						this.getData();
					}
				})
			},


			//离职框取消
			leaveCancel: function() {
				this.leaveVisible = false;
				this.leaveId = "";
			},

			//修改权限按钮
			modify: function(record) {
				this.modifyVisible = true;
				this.modifyId = record.id;
				axios.post("/role/list", {}).then((res) => {
					if (res.data.code === '200') {
						this.roles = res.data.data;
					}
				})

        axios.post("/employee/role", {
          "id": this.modifyId
        }).then((res) => {
          if (res.data.code === '200') {
            this.modifyRole = res.data.data;
          }
        })

			},

			//修改权限下拉框监听
			handleChange(value) {
				this.modifyRole = value;
			},

			//修改权限确认
			modifyOk: function() {
				this.leaveVisible = false;
				axios.post("/employee/roleSave", {
					"id": this.modifyId,
					"roleId": this.modifyRole
				}).then((res) => {
					if (res.data.code === '200') {
            this.$message.success(res.data.msg);
						this.modifyVisible = false;
						this.modifyId = "";
						this.modifyRole = "";
					}
				})
			},

			//离职框取消
			modifyCancel: function() {
				this.modifyVisible = false;
				this.modifyId = "";
				this.modifyRole = "";
			},
      popupScroll() {
        console.log('popupScroll');
      },

			//添加按钮
			add: function() {
				this.addVisible = true;
        //查看现有权限
				axios.post("/permissions/checkRole", {}).then((res) => {
						if (res.data.code === '200') {
							this.addRoles = res.data.data;
						}
					})
			},


			//添加员工  权限下拉框
			selectHandleChange(value) {
				this.selectRole = value;
			},

			//添加弹窗取消按钮
			addCancel: function() {
				this.userName = "";
				this.phoneNumber = "";
        this.position = "";
				console.log(this.area);
			},

			//添加弹窗确定按钮
			addOk: function() {
				this.addVisible = false;
				axios.post("/employee/add", {
					"name": this.userName,
					"phone": this.phoneNumber,
					"area": this.area,
					"role": this.selectRole,
					"department": this.department,
					"position": this.position
				}).then((res) => {
					if (res.data.code === '200') {
            this.$message.success(res.data.msg);
						this.userName = "";
						this.phoneNumber = "";
						this.position = "";
						// this.department = "";
					}else{
            this.$message.error(res.data.msg);
          }
				})
			},

			//部门选择下拉框
			departHandleChange(value) {
				this.department = value;
			},
		},

		watch: {
			pagination() {
				this.getData();
			}
		}
	}
</script>
