<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>软件下载</a-breadcrumb-item>
      <a-breadcrumb-item>软件下载</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="软件下载"/>
    <br>
    <a-row>
      <a-col :xs="22" :xl="22" :xxl="22"></a-col>
      <a-col :span="1">
        <a-button type="primary" v-on:click="download">
          软件下载
        </a-button>
      </a-col>
    </a-row>

      <div class="fontStyle">
      <h1>1.a.02</h1>
      <p>新增功能：住户信息与积分导出、垃圾袋发放记录导出</p>
      <p>发行时间：2023-01-13</p>
    </div>

    <br><hr><br>

    <div class="fontStyle">
      <h1>1.a.01</h1>
      <p>新增功能：小区月度台账照片导出</p>
      <p>发行时间：2023-01-09</p>
    </div>


  </div>
</template>
<script>


export default {
  data() {
    return {};
  },
  created() {

  },
  methods: {
    download:function (){
      window.open("https://cdn.gxhbpaas.cn/%E6%8A%80%E6%9C%AF%E9%83%A8/util0130/%E7%94%B3%E8%BE%89%E6%96%87%E4%BB%B6%E5%AF%BC%E5%87%BA%E5%B7%A5%E5%85%B7.zip", "_blank");
}
  },

  //监听事件
  watch: {

  }
};
</script>
<style>
.fontStyle {
  margin-left: 17px;
}

.fontStyle h1 {
  font-size: 35px;
  font-weight: bolder;
}

.fontStyle p {
  font-size: 16px;
  font-weight: 500;
}
</style>
