<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>垃圾投放</a-breadcrumb-item>
      <a-breadcrumb-item>设备投放</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="设备投放"/>
    <br>
    <a-row type="flex">
      <a-col><span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <br>
    <a-row type="flex">
      <a-col>
        <span style="line-height: 32px">时间：</span>
      </a-col>
      <a-col>
        <a-range-picker @change="onChange" :locale="locale" :value="createValue"/>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col offset="1">
        <a-button v-on:click="search" type="primary">查询</a-button>
        <a-button v-on:click="reset">重置</a-button>
      </a-col>
    </a-row>
    <br>
    <a-table :columns="columns" :data-source="list" :pagination="pagination"
             :rowKey="(record,index)=>{return index}" @change="handleTableChange">
<span slot="garbageType" slot-scope="garbageType">
				<a-tag :color="garbageType === '1' ? '#2db7f5' : garbageType==='2'?'#108ee9':'#87d068'">
					{{ garbageType === '1' ? '厨余垃圾' : garbageType === '2' ? '其他垃圾' : '可回收垃圾' }}
				</a-tag>
			</span>
    </a-table>

  </div>
</template>

<script>
import axios from "axios";
import locale from "ant-design-vue/lib/date-picker/locale/zh_CN";

const columns = [{
  title: '设备号',
  dataIndex: 'terminalNo',
  key: 'terminalNo',
},
  {
    title: '垃圾类型',
    dataIndex: 'garbageType',
    key: 'garbageType',
    scopedSlots: {
      customRender: 'garbageType'
    }
  },
  {
    title: '投放重量(Kg)',
    dataIndex: 'weight',
    key: 'weight',
  },

  {
    title: '投放时间',
    dataIndex: 'createDate',
    key: 'createDate',
  }
];
export default {
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      areaList: [],   //小区下拉框数据源
      list: [],
      areaCode: "", //当前选中的地区值
      startTime: "",
      endTime: "",
      createValue: [],
      locale,
    }
  },
  created() {
    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
      }
    });
    this.getDate();
  },
  methods: {
    //下拉框选中事件
    handleAreaChange: function (value) {
      this.areaCode = value;
    },
    reset: function () {
      //清空搜索条件
      // this.status = "0"
      this.areaCode = "all";
      // this.userName = "";
      this.startTime = "";
      this.endTime = "";
      this.pagination.current = 1;
      this.createValue = [];
      this.getData();
    },
    //搜索按钮
    search: function () {
      this.pagination.current = 1;
      this.getData();
    },

    //table 分页监听事件
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },

    //从服务器获取数据
    getData: function () {
      //从服务器获取数据
      axios.post("/garbage/throwList", {
        "pageSize": this.pagination.current,
        "areaCode": this.areaCode,
        "startTime": this.startTime,
        "endTime": this.endTime,
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },

    //获取当前日期
    getDate:function (){
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month >= 10 ? month : '0' + month;
      day = day >= 10 ? day : '0' + day;
      this.startTime = year + '-' + month + '-' + day;
      this.endTime = year + '-' + month + '-' + day;
      this.createValue = [this.startTime, this.endTime];
    },

    //时间选择框监听
    onChange(date, dateString) {
      this.createValue = date;
      this.startTime = dateString[0]
      this.endTime = dateString[1]
    },
  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    }
  }
}
</script>
