<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>住户管理</a-breadcrumb-item>
      <a-breadcrumb-item>户信息管理</a-breadcrumb-item>
    </a-breadcrumb>


    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="户信息管理"/>
    <br>

    <a-row>
      <a-col :xs="22" :xl="22" :xxl="23">
      </a-col>
      <a-col :span="1">
        <a-button type="primary" v-on:click="add">
          添加
        </a-button>
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-col span="24"><span style="line-height: 32px"></span>
        <area-select v-on:areaCodeChange="areaCodeChange" ref="areaSelect"></area-select>
      </a-col>
      </a-row>
    <a-row type="flex">
      <a-col offset="0"><span style="line-height: 32px;text-align: center">户主：</span></a-col>
      <a-col>
        <a-input placeholder="户主" v-model="name" style="width:120px"></a-input>
      </a-col>
      <a-col offset="1"><span style="line-height: 32px">手机号：</span></a-col>
      <a-col>
        <a-input placeholder="手机号码" v-model="userPhone" style="width:120px"></a-input>
      </a-col>
      <a-col offset="1"><span style="line-height: 32px">卡号：</span></a-col>
      <a-col>
        <a-input placeholder="户码" v-model="userCode" style="width:150px"></a-input>
      </a-col>
      <a-col offset="1"><span style="line-height: 32px">地址：</span></a-col>
      <a-col>
        <a-input placeholder="地址" v-model="address" style="width:150px"></a-input>
      </a-col>
    </a-row>
    <br/>
    <a-row>
      <a-col offset="1">
        <a-button type="primary" v-on:click="search">搜索</a-button>
        <a-button v-on:click="reset">重置</a-button>
      </a-col>
    </a-row>
    <br>


    <a-table :columns="columns" :data-source="list" :pagination="pagination"
             :rowKey="(record,index)=>{return index}" @change="handleTableChange">
			<span slot="operation" slot-scope="text,record">
				<a-button v-on:click="edit(record.id)" type="primary">编辑</a-button>
				<a-button v-on:click="giftBag(record.id)" type="default">赠送积分</a-button>
				<a-button v-on:click="giftCoin(record.id)" type="default">赠币</a-button>
				<a-button v-on:click="detailCoin(record.id)" type="default">益币详情</a-button>
				<a-button v-on:click="detailBag(record.id)" type="default">积分详情</a-button>
			</span>
    </a-table>
    <!--编辑弹框-->
    <a-modal v-model="editVisible" @ok="handleOk" okText="确定" cancelText="取消" @cancel="handleCancel">
      <p>姓名：
        <a-input v-model="alertUserName">

        </a-input>
      </p>
      <p>手机号码：
        <a-input v-model="alertUserPhone">

        </a-input>
      </p>
      <p>详细地址：
        <a-input v-model="alertDetailAddress">

        </a-input>
      </p>
      <p>IC卡号：
        <a-input v-model="alertDetailCode" disabled>

        </a-input>
      </p>
      <p>环保币：
        <br>
        <a-input v-model="alertAmount"  disabled>

        </a-input>
      </p>
      <p>积分：
        <a-input v-model="alertPoint" disabled>
        </a-input>
      </p>
    </a-modal>
    <!--添加弹窗-->
    <a-modal v-model="addVisible" @ok="addHandleOk" okText="确定" cancelText="取消" @cancel="addHandleCancel">
      <p>户主姓名：
        <a-input v-model="addUserName"></a-input>
      </p>
      <p>手机号码：
        <a-input v-model="addUserPhone"></a-input>
      </p>
      <p>幢号：
        <a-input-number v-model="addZ"></a-input-number>
      </p>
      <p>单元：
        <a-input-number v-model="addD"></a-input-number>
      </p>
      <p>楼层+户号：
        <a-input-number v-model="addCode"></a-input-number>
      </p>
    </a-modal>
    <!--赠袋弹窗-->
    <a-modal v-model="bagVisible" @ok="bagHandleOk" okText="确定" cancelText="取消" @cancel="bagHandleCancel">
      <p>请输入卷数(*30)：
        <a-input v-model="bagCount"></a-input>
      </p>
      <p>备注：
        <a-input v-model="bagRemarks"></a-input>
      </p>
    </a-modal>
    <!--赠币弹窗-->
    <a-modal v-model="coinVisible" @ok="coinHandleOk" okText="确定" cancelText="取消" @cancel="coinHandleCancel">
      <p>请输入币数：
        <a-input v-model="coinCount"></a-input>
      </p>
      <p>备注：
        <a-input v-model="coinRemarks"></a-input>
      </p>
    </a-modal>
    <!--益币详情弹窗-->
    <a-modal v-model="detailCoinVisible" :width="800">
      <a-table :columns="detailCoinColumns" :data-source="detailCoinList"
               :rowKey="(record,index)=>{return index}">
        <span slot="changeType" slot-scope="changeType">
					<a-tag :color="changeType === '1' ? 'green' :  'red'">
						{{ changeType === '1' ? '增加' : '减少' }}
					</a-tag>
				</span>
      </a-table>
      <template slot="footer">
        <a-button @click="detailCoinHandleCancel">关闭</a-button>
      </template>
    </a-modal>
    <!--福袋详情弹窗-->
    <a-modal v-model="detailBagVisible" :width="800">
      <a-table :columns="detailBagColumns" :data-source="detailBagList" :rowKey="(record,index)=>{return index}">
				<span slot="changeType" slot-scope="changeType">
					<a-tag :color="changeType === '1' ? 'green' :  'red'">
						{{ changeType === '1' ? '增加' : '减少' }}
					</a-tag>
				</span>
      </a-table>
      <template slot="footer">
        <a-button @click="detailBagHandleCancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import axios from "axios";
import areaSelect from "../common/areaSelect.vue";

//首页标题栏
const columns = [{
  title: '户主姓名',
  dataIndex: 'userName',
  key: 'userName',

},
  {
    title: '户主手机号',
    dataIndex: 'userPhone',
    key: 'userPhone',

  },
  {
    title: '详细地址',
    dataIndex: 'address',
    key: 'address',

  },
  {
    title: '户编码（卡号）',
    dataIndex: 'detailCode',
    key: 'detailCode',

  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: {
      customRender: 'operation'
    }

  }
];

//币变动明细标题栏
const detailCoinColumns = [{
  title: '变动前益币',
  dataIndex: 'beforeBalance',
  key: 'beforeBalance',
},
  {
    title: '变动数量',
    dataIndex: 'amount',
    key: 'amount',

  },
  {
    title: '当前益币',
    dataIndex: 'balance',
    key: 'balance',

  },
  {
    title: '变动时间',
    dataIndex: 'createDate',
    key: 'createDate',

  },
  {
    // 0增加 1减少
    title: '变动方式',
    dataIndex: 'changeType',
    key: 'changeType',
    scopedSlots: {
      customRender: 'changeType'
    }
  },
];

//积分变动明细标题栏
const detailBagColumns = [{
  title: '变动前数量',
  dataIndex: 'beforeBalance',
  key: 'beforeBalance',
},
  {
    title: '变动数量',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: '现在数量',
    dataIndex: 'balance',
    key: 'balance',
  },
  {
    title: '变动时间',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '备注',
    dataIndex: 'remarks',
    key: 'remarks',
  },
  {
    title: '变动方式',
    dataIndex: 'changeType',
    key: 'changeType',
    scopedSlots: {
      customRender: 'changeType'
    }
  },

]
export default {
  components: {
    areaSelect
  },
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      list: [],
      alertAmount: "", // 弹出框-环保币余额
      alertDetailAddress: "", //弹出框 用户地址
      alertDetailCode: "", //弹出框数据-IC卡号
      alertPoint: "", //弹出框-积分
      alertUserName: "", //弹出框数据-用户姓名
      alertUserPhone: "", //弹出框数据-手机号
      alertId: "", //弹出框用户主键id
      bagId: "", //弹出框用户主键id
      coinId: "", //弹出框用户主键id
      name: "", //搜索框--户主姓名
      areaName: "", //搜索框-小区名称
      userPhone: "", //搜索框--手机号码
      userCode: "", //   搜索框用户卡号
      address: "",//搜索框地址
      areaCode: "", //下拉框选中的地区值
      editVisible: false, //编辑弹框
      addVisible: false, //添加弹窗
      bagVisible: false, //赠袋弹窗
      coinVisible: false, //赠币弹窗
      addUserName: "", //添加框 住户姓名
      addUserPhone: "", //添加框 住户手机号
      addZ: 0, //添加框 幢
      addD: 0, //添加框  栋
      addCode: 0, //添加框  户号
      bagCount: "", //赠送框 袋数
      coinCount: 0, //赠送框 币数
      bagRemarks: "", //赠送框 袋备注
      coinRemarks: "", //赠送框 币备注
      detailCoinVisible: false, //益币详情弹出窗
      detailBagVisible: false, //积分详情弹出窗
      detailCoinColumns, //益币详情
      detailBagColumns, //福袋详情
      detailCoinList: [], //益币详情数据列表
      detailBagList: [], //积分详情数据列表
      areaList: [],

    }
  },

  created() {
  },


  methods: {
    //重置搜索条件
    reset: function () {
      this.$refs.areaSelect.reset();
      this.name = "";
      this.areaCode = "";
      this.userPhone = "";
      this.userCode = "";
      this.address = "";
      this.pagination.current = 1;
      this.getData();

    },
    //列表编辑按钮事件
    edit: function (id) {
      //这个id就是这行数据在数据库里的主键id
      //传这个id到服务器获取数据
      this.editVisible = true;
      this.alertId = id;
      axios.post("/resident/userInfo", {
        "id": id
      }).then((res) => {
        if (res.data.code === '200') {
          //请求成功,需要将服务器返回的值放入内存中
          this.alertAmount = res.data.data.amount;
          this.alertDetailAddress = res.data.data.detailAddress;
          this.alertDetailCode = res.data.data.detailCode;
          this.alertPoint = res.data.data.points;
          this.alertUserName = res.data.data.userName;
          this.alertUserPhone = res.data.data.userPhone;
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },

    //编辑弹框保存事件
    handleOk: function () {
      this.visible = false;
      axios.post("/resident/saveUser", {
        "id": this.alertId,
        "detailAddress": this.alertDetailAddress,
        "name": this.alertUserName,
        "userPhone": this.alertUserPhone,
        "number": this.alertDetailCode
      }).then((res) => {
        if (res.data.code === '200') {
          this.editIndex();
          this.editVisible = false;
          this.$notification.open({
            message: "来自申辉管理后台的提醒",
            description: res.data.msg,
            onClick: () => {
            },
          });
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },

    //弹出框点击取消的事件
    handleCancel: function () {
      this.editIndex();
      this.editVisible = false;
      this.alertId = "";
    },

    //编辑弹框清空
    editIndex: function () {
      this.alertAmount = ""; // 弹框狂-环保币余额
      this.alertDetailAddress = ""; //弹出框 用户地址
      this.alertDetailCode = ""; //弹出框数据-IC卡号
      this.alertPoint = ""; //弹出框-积分
      this.alertUserName = ""; //弹出框数据-用户姓名
      this.alertUserPhone = ""; //弹出框数据-手机号
      this.alertId = ""; //弹出框用户
    },
    //列表分页监听事件
    handleTableChange: function (pagination) {
      this.pagination = pagination;
    },
    //搜索按钮
    search: function () {
      this.pagination.current = 1;
      this.getData();
    },

    //添加按钮事件
    add: function () {
      if (this.areaCode.length === 14 || this.areaCode.length === 15) {

        this.addVisible = true;
      } else {
        this.$message.error('请在上方选择小区之后再添加住户');
      }

    },

    //添加弹框保存事件
    addHandleOk: function () {
      if (this.addD === 0 || this.addZ === 0 || this.addCode === 0 || this.addD === null || this.addZ ===
          null || this.addCode === null) {
        this.$message.error("户信息未填写完全");
      } else {
        axios.post("/resident/add", {
          "userName": this.addUserName,
          "userPhone": this.addUserPhone,
          "addZ": this.addZ,
          "addD": this.addD,
          "addCode": this.addCode,
          "areaCode": this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            this.addIndex();
            this.addVisible = false;
            this.$message.success(res.data.msg);
            this.getData();
          } else {
            this.$message.error(res.data.msg);
          }
        })
      }

    },

    //添加框点击取消的事件
    addHandleCancel: function () {
      this.addIndex();
      this.addVisible = false;
    },
    //添加弹框数据清空

    addIndex: function () {
      this.addUserName = ""; // 住户姓名
      this.addUserPhone = ""; // 住户手机号
      this.addZ = 0; // 幢
      this.addD = 0; //  栋
      this.addCode = 0;
    },

    //

    //根据搜索栏字段搜索数据
    getData: function () {
      //从服务器获取数据
      axios.post("/resident/residentList", {
        "pageSize": this.pagination.current,
        "areaCode": this.areaCode,
        "areaName": this.areaName,
        "name": this.name,
        "phone": this.userPhone,
        "userCard": this.userCode,
        "address": this.address,
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data.list;
          this.pagination.total = parseInt(res.data.data.count);
        }
      })
    },
    //赠袋弹窗
    giftBag: function (id) {
      this.bagVisible = true;
      this.bagId = id;
    },
    //赠袋弹窗确定按钮
    bagHandleOk: function () {
      if (this.bagCount === 0 || this.bagRemarks === "") {
        this.$notification.open({
          message: "来自申辉管理后台的提醒",
          description: "信息未填写完全",
          onClick: () => {
          },
        });
      } else {
        axios.post("/resident/givingPoints", {
          "id": this.bagId,
          "number": this.bagCount * 30,
          "remarks": this.bagRemarks
        }).then((res) => {
          if (res.data.code === '200') {
            //执行成功
            this.$notification.open({
              message: "来自申辉管理后台的提醒",
              description: res.data.msg,
              onClick: () => {
              },
            });
            this.bagCount = '';
            this.bagRemarks = '';
            this.bagVisible = false;
            this.list = res.data.data.list;
            this.pagination.total = parseInt(res.data.data.count);
          }
        })
      }
    },
    //赠袋弹窗取消按钮
    bagHandleCancel: function () {
      this.bagCount = '';
      this.bagRemarks = '';
      this.bagVisible = false;
    },
    //赠币弹窗
    giftCoin: function (id) {
      this.coinVisible = true;
      this.coinId = id;
    },
    //赠币弹窗确定按钮
    coinHandleOk: function () {
      if (this.coinCount === 0 || this.coinRemarks === "") {
        this.$notification.open({
          message: "来自申辉管理后台的提醒",
          description: "信息未填写完全",
          onClick: () => {
          },
        });
      } else {
        axios.post("/resident/givingAmount", {
          "id": this.coinId,
          "coin": this.coinCount,
          "remarks": this.coinRemarks
        }).then((res) => {
          if (res.data.code === '200') {
            //执行成功
            this.$notification.open({
              message: "来自申辉管理后台的提醒",
              description: res.data.msg,
              onClick: () => {
              },
            });
            this.coinCount = '';
            this.coinRemarks = '';
            this.coinVisible = false;
            this.list = res.data.data.list;
            this.pagination.total = parseInt(res.data.data.count);


          }
        })
      }
    },
    //赠币弹窗取消按钮
    coinHandleCancel: function () {
      this.coinCount = '';
      this.coinRemarks = '';
      this.coinVisible = false;
    },
    //益币详情按钮
    detailCoin: function (record) {
      this.detailCoinVisible = true;
      axios.post("/resident/accountChange", {
        "id": record,
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.detailCoinList = res.data.data.list;
        }
      })
    },
    //益币详情关闭
    detailCoinHandleCancel: function () {
      this.detailCoinVisible = false;
    },
    //福袋详情按钮
    detailBag: function (record) {
      this.detailBagVisible = true;
      axios.post("/resident/pointChange", {
        "id": record,
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.detailBagList = res.data.data.list;
        }
      })
    },
    //福袋详情关闭
    detailBagHandleCancel: function () {
      this.detailBagVisible = false;
    },

    //获取子组件传值
    areaCodeChange: function (areaCode) {
      this.areaCode = areaCode;
    },

  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    }
  }
}
</script>
<style>

</style>
