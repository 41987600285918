import Login from './views/login'  //登录
import Index from './views/index'   //首页，图标页

import userList from './views/user/list'     //住户管理-户信息管理

import data from "./views/data"    //数据中心-间断数据统计

import jh from "./views/throw/jh.vue"     //垃圾投放-建湖
import terminalThrow from "./views/throw/terminalThrow.vue"  //垃圾投放-设备投放

import bagSend from './views/bag/send'       //垃圾袋管理-垃圾袋库存
import bagList from './views/bag/bagList'    //垃圾袋管理-垃圾袋发放记录

import equipmentEquipment from './views/equipment/equipment'   //设备管理-设备列表
import equipmentTerminal from './views/equipment/terminal'      //设备管理-监控列表
import video from './views/common/video'                      //观看监控

import employeeEmployee from './views/employee/employee'       //员工管理-员工列表

import download from "./views/download/download"   //软件下载-软件下载


//添加路由地址
const routers = [
    //登录页面
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            keepAlive: false
        }

    },
    //首页
    {
        path: '/',
        component: Index,
        meta: {
            keepAlive: true
        }

    },
    {
        path: '/user/list',
        component: userList,
        meta: {
            keepAlive: true
        }

    },
    {
        path: '/equipment/equipment',
        component: equipmentEquipment,
        meta: {
            keepAlive: true
        }

    },
    {
        path: '/equipment/terminal',
        component: equipmentTerminal,
        meta: {
            keepAlive: true
        }

    },
    {
        path: '/employee/employee',
        component: employeeEmployee,
        meta: {
            keepAlive: true
        }

    },
    {
        path: '/video',
        component: video,

    },
    {
        path: '/bag/send',
        component: bagSend,
        meta: {
            keepAlive: true
        }

    },
    {
        path: '/bag/bagList',
        component: bagList,
        meta: {
            keepAlive: true
        }

    },

    {
        path: '/throw/terminalThrow',
        component: terminalThrow,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/throw/jh',
        component: jh,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/throw/data',
        component: data,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/download/download',
        component: download,
        meta: {
            keepAlive: true
        }
    }


]

export default routers
