<template>
  <a-row type="flex">
    <a-col offset="0"><span style="line-height: 32px;text-align: center">区域：</span></a-col>
    <a-col>
      <a-select style="width: 120px" v-model="selectProvince" @change="handleProvinceChange">
        <a-select-option v-for="province in provinces" :key="province.code">
          {{ province.name }}
        </a-select-option>
      </a-select>
    </a-col>
<!--    暂时隐藏掉第二级-->
    <a-col class="citySelect">
      <a-select style="width: 120px" v-model="selectCity"  v-show=false placeholder="所有市" @change="handleCitiesChange">
        <a-select-option v-for="city in cities" :key="city.code">
          {{ city.name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col>
      <a-select style="width: 120px" v-model="selectRegion" placeholder="所有区" @change="handleRegionChange"
                v-show="regionStatus">
        <a-select-option v-for="region in regions" :key="region.code">
          {{ region.name }}
        </a-select-option>

      </a-select>
    </a-col>
    <a-col>
      <a-select style="width: 120px" v-model="selectStreet" placeholder="所有街道" @change="handleStreetChange"
                v-show="streetStatus">
        <a-select-option v-for="street in streets" :key="street.code">
          {{ street.name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col>
      <a-select style="width: 120px" v-model="selectCommunity" placeholder="所有社区" @change="handleCommunityChange"
                v-show="communityStatus">
        <a-select-option v-for="community in communities" :key="community.code">
          {{ community.name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col>
      <a-select style="width: 120px" v-model="selectApartment" placeholder="所有小区" @change="handleApartmentChange"
                v-show="apartmentStatus">
        <a-select-option v-for="apartment in apartments" :key="apartment.code">
          {{ apartment.name }}
        </a-select-option>
      </a-select>
    </a-col>
    <!--	<a-col :span="8"></a-col>-->
  </a-row>
</template>

<script>
import axios from 'axios'

export default {
  name: "areaSelect",
  data() {
    return {
      areaCode: "",   //当前选中的地区值
      provinces: [],  //省下拉框
      cities: [],     //市下拉框
      regions: [],    //区下拉框
      streets: [],    //街道下拉框
      communities: [], //社区下拉框
      apartments: [],  //  小区下拉框
      selectProvince: "", //选中的省值
      selectCity: "", //选中的市值
      selectRegion: "", //选中的区值
      selectStreet: "", //选中的街道值
      selectCommunity: "", //选中的社区值
      selectApartment: "", //选中的小区值
      regionStatus: false,  //  区
      streetStatus: false,   //街道
      communityStatus: false,  //社区
      apartmentStatus: false,  //小区
    }
  },
  created() {
    //加载初始下拉框数据
    axios.post("/area/areaSelect", {"code": "0"}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.provinces = res.data.data;
        this.selectProvince = this.provinces[0].code;

        // this.areaCode=this.selectProvince;
      }
    })

  },
  methods: {
    //省下拉框监听方法
    handleProvinceChange: function (value) {
      this.selectProvince = value;

    },
    //市下拉框监听方法
    handleCitiesChange: function (value) {
      this.selectCity = value;

    },
    //区下拉框监听方法
    handleRegionChange: function (value) {
      this.selectRegion = value;

    },
    //街道下拉框监听方法
    handleStreetChange: function (value) {
      this.selectStreet = value;

    },
    //社区下拉框监听方法
    handleCommunityChange: function (value) {
      this.selectCommunity = value;

    },
    //小区下拉框监听方法
    handleApartmentChange: function (value) {
      this.selectApartment = value;

    },
    //重置
    reset: function () {
      //强制更改

      axios.post("/area/areaSelect", {"code": "0"}).then((res) => {
        if (res.data.code === '200') {
          //请求成功
          this.provinces = res.data.data;
          this.selectProvince = this.provinces[0].code;
          this.selectCity = "";
          this.selectRegion = "";
          this.selectStreet = "";
          this.selectCommunity = "";
          this.selectApartment = "";
          this.regionStatus = false;
          this.streetStatus = false;
          this.communityStatus = false;
          this.apartmentStatus = false;
        }
      })
    }
  },
  watch: {
    //省下拉框选中值监听事件
    selectProvince() {
      //选中的省值更新，areaCode更新
      this.areaCode = this.selectProvince;
      //清空后面的6个选择框
      this.selectCity = "";
      this.selectRegion = "";
      this.selectStreet = "";
      this.selectCommunity = "";
      this.selectApartment = "";
      this.regionStatus = false;
      this.streetStatus = false;
      this.communityStatus = false;
      this.apartmentStatus = false;
      //连带着更新市下拉框的值
      axios.post("/area/areaSelect", {
        "code": this.areaCode
      }).then((res) => {
        if (res.data.code === '200') {
          //请求成功
          this.cities = res.data.data;
        }
      })
    },
    //市下拉框选中值监听事件
    selectCity() {
      if (this.selectCity !== "") {
        this.areaCode = this.selectCity;
        if (this.areaCode.length >= 14) {
          this.regionStatus = false;
        } else {
          this.regionStatus = true;
        }
        //清空后面的4个选择框
        this.selectRegion = "";
        this.selectStreet = "";
        this.selectCommunity = "";
        this.selectApartment = "";

        //隐藏区后面的下拉框
        this.streetStatus = false;
        this.communityStatus = false;
        this.apartmentStatus = false;


        axios.post("/area/areaSelect", {
          "code": this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            //请求成功
            this.regions = res.data.data;
          }
        })
      }
    },
    //区下拉框选中值监听事件
    selectRegion() {
      if (this.selectRegion !== "") {
        this.areaCode = this.selectRegion;
        if (this.areaCode.length >= 14) {
          this.streetStatus = false;
        } else {
          this.streetStatus = true;
        }
        //清空后面的3个选择框
        this.selectStreet = "";
        this.selectCommunity = "";
        this.selectApartment = "";

        this.communityStatus = false;
        this.apartmentStatus = false;


        axios.post("/area/areaSelect", {
          "code": this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            //请求成功
            this.streets = res.data.data;
          }
        })
      }

    },
    //街道下拉框选中值监听事件
    selectStreet() {
      if (this.selectStreet !== "") {
        this.areaCode = this.selectStreet;
        if (this.areaCode.length >= 14) {
          this.communityStatus = false;
        } else {
          this.communityStatus = true;
        }
        //清空后面的2个选择框
        this.selectCommunity = "";
        this.selectApartment = "";


        this.apartmentStatus = false;


        axios.post("/area/areaSelect", {
          "code": this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            //请求成功
            this.communities = res.data.data;
          }
        })
      }
    },
    //社区下拉框选中值监听事件
    selectCommunity() {
      if (this.selectCommunity !== "") {
        this.areaCode = this.selectCommunity;
        if (this.areaCode.length >= 14) {
          this.apartmentStatus = false;
        } else {
          this.apartmentStatus = true;
        }
        //清空后面的1个选择框
        this.selectApartment = "";
        axios.post("/area/areaSelect", {
          "code": this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            //请求成功
            this.apartments = res.data.data;
          }
        })
      }
    },
    //小区下拉框选中值监听事件
    selectApartment() {
      if (this.selectApartment !== "") {
        this.areaCode = this.selectApartment;
        axios.post("/area/areaSelect", {
          "code": this.areaCode
        }).then((res) => {
          if (res.data.code === '200') {
            this.apartmentStatus=res.data.data;
          }
        });
      }
    },


    areaCode() {
      //监听areaCode，发现areaCode变化之后传递给父组件
      this.$emit("areaCodeChange", this.areaCode);
    }

  }
}
</script>

<style scoped>

</style>
