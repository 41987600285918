<template>
  <div>
    <a-breadcrumb style="padding-top: 15px">
      <a-breadcrumb-item><a href="/public">首页</a></a-breadcrumb-item>
      <a-breadcrumb-item>垃圾投放</a-breadcrumb-item>
      <a-breadcrumb-item>间段数据统计</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="间段数据统计"/>
    <br>

    <a-row type="flex">
      <a-col><span style="line-height: 32px">小区名称：</span>
        <a-select style="width: 120px" v-model="areaCode" @change="handleAreaChange">
          <a-select-option v-for="area in areaList" :key="area.code">
            {{ area.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <br>

    <a-row type="flex">
      <a-col>
        <span style="line-height: 32px">时间：</span>
      </a-col>
      <a-col>
        <a-range-picker @change="onChange" :locale="locale" :value="createValue"/>
      </a-col>
    </a-row>
    <br>

    <a-row>
      <a-col offset="1">
        <a-button v-on:click="search" type="primary">查询</a-button>
      </a-col>
    </a-row>
    <br>

    <a-card title="数据统计" :bordered="false" style="width: 300px">
      <p>厨余：{{ list.kitchen }}Kg</p>
      <p>其他：{{ list.other }}桶</p>
      <p>有毒有害：{{ list.harmful }}Kg</p>
      <p>塑料：{{ list.plastics }}Kg</p>
      <p>金属：{{ list.metal }}Kg</p>
      <p>纸张：{{ list.paper }}Kg</p>
      <p>玻璃：{{ list.glass }}Kg</p>
      <p>织物：{{ list.clothes }}Kg</p>
    </a-card>
  </div>
</template>
<script>
import axios from "axios";
import locale from "ant-design-vue/lib/date-picker/locale/zh_CN";

const columns = [];
export default {
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: total => `共 ${total} 条`, // 显示总数
      },
      columns,
      areaList: [],   //小区下拉框数据源
      list: [],
      areaCode: "", //当前选中的地区值
      startTime: "",
      endTime: "",
      createValue: [],
      editId: '',
      editVisible: false,
      weightEdit: '',
      timeEdit: '',
      downVisible: false,
      areaWithoutAllList: [],
      areaCodeWithoutAll: '',
      monthSelect: '',
      garbageType: '0',
      locale,
    }
  },
  created() {

    axios.post("/area/areaSelect", {}).then((res) => {
      if (res.data.code === '200') {
        //请求成功
        this.areaList = res.data.data;
        this.areaCode = this.areaList[0].code;
      }
    })

    this.getDate();
  },
  methods: {
    //下拉框选中事件
    handleAreaChange: function (value) {
      this.areaCode = value;
    },

    //搜索按钮
    search: function () {
      this.getData();

    },

    //从服务器获取数据
    getData: function () {
      //从服务器获取数据
      axios.post("/jianhu2/data ", {
        "code": this.areaCode,
        "startTime": this.startTime,
        "endTime": this.endTime,
      }).then((res) => {
        if (res.data.code === '200') {
          //执行成功
          this.list = res.data.data;

        }
      })
    },

    //获取当前日期
    getDate: function () {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let sevenDay = date.getTime() - 7 * 86400 * 1000;
      date.setTime(sevenDay);
      this.startTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.endTime = year + '-' + month + '-' + day;
      this.createValue = [this.startTime, this.endTime];

    },

    //时间选择框监听
    onChange(date, dateString) {
      this.createValue = dateString;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },

  },
  watch: {
    pagination() {
      this.getData();
    },
    areaCode() {
      this.pagination.current = 1;
      this.getData();
    }
  }
}
</script>
